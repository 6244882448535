//
// Home styles
// --------------------------------------------------

.english-only {
  text-align: center;
  color: #666;
  text-shadow: #fff 0 1px 1px;
  margin: 15px;

  span {
    vertical-align: middle;
    padding-right: 0px;
  }
}

.page-home {
  .section-hello {
    display: table;

    .hello-about {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .hello-contact {
      text-align: center;
      margin-top: 30px;
    }

    .hello-contact-profiles {
      font-size: 35px;
      @include links-gray;
    }
    .hello-contact-profiles span {
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
