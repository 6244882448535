//
// Blog styles
// --------------------------------------------------

#logo .target-blog {
  color: #369;
  font-style: italic;

  &:hover {
    color: darken(#369, 10%);
  }
}


.entry-item .entry-header,
.entry-item .entry-content,
.entry-item .entry-footer,
.entry-item .related,
.entry-item .disqus {
  margin-bottom: 30px;
}


// Make the first title of the page more prominent
.entry-title {
  font-size: 36px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  // .page-header
  // padding-bottom: 9px;
  margin: 20px 0 20px;
  // border-bottom: 1px solid #eeeeee;

  a:hover {
    text-decoration: none;
  }
}

.entry-meta {
  color: $gray-light;

  a {
    color: $gray;
  }
  a:hover {
    color: $link-color;
    text-decoration: none;
  }
}

.entry-meta .icon-tag {
  margin-right: 5px;
}


.entry-meta .entry-categories a,
.entry-meta .entry-tags a {
  margin-left: 10px;
}

.entry-content {
  line-height: 1.7;

  h1, h2, h3, h4, h5 {
    // font-weight: bold;
  }

  // Increase bottom margin. It looks better.
  h2 {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 15px;
  }

  img {
    display: block;
    margin: 30px auto;
    max-width: 90%;
    -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px $gray-lighter, 0 2px 5px 4px rgba(0,0,0,.2);
    -moz-box-shadow: 0 0 0 3px #fff, 0 0 0 4px $gray-lighter, 0 2px 5px 4px rgba(0,0,0,.2);
    box-shadow: 0 0 0 3px #fff, 0 0 0 4px $gray-lighter, 0 2px 5px 4px rgba(0,0,0,.2);
  }

  // An image gallery allows to list more than one image into to same row.
  .image-gallery {
    display: block;
    max-width: 90%;
    margin: 30px auto;
    text-align: center;

    img {
      display: inline-block;
      margin: auto 10px;
    }
  }

  .entry-logo-icon {
    display: inline;
    float: left;
    margin: 0 15px 10px 0;
    padding: 5px;
    max-width: 150px;
  }
}

// Reconfigure the .alert content when inside the .entry-content.
// Only h4 and p are supposed to be there.
.entry-content .alert {
  font-size: 14px;

  h4, p {
    margin: 0px;
  }

  h4 + p,
  p + p {
    margin-top: 10px;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
  }
}


.entries-titlelist {
  // .list-unstyled
  padding-left: 0;
  list-style: none;
}

.entries-titlelist-item {
  margin-top: 10px;
  margin-bottom: 10px;

  time {
    display: inline-block;
    margin-right: 10px;
    width: 100px;
    color: $gray-light;
    text-align: right;
    font-size: 90%;
  }
}
