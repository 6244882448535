//
// Customizations for simonecarletti.IT
// --------------------------------------------------

.icon-tag-snslytics:before {
  content: "\f080";
}
.icon-tag-domini:before {
  content: "\f0ac";
}
.icon-tag-rss:before {
  content: "\f09e";
}
.icon-tag-libri:before {
  content: "\f02d";
}
.icon-tag-programmazione:before {
  content: "\f121";
}
.icon-tag-seo:before {
  content: "\f002";
}
.icon-tag-sicurezza:before {
  content: "\f132";
}
.icon-tag-software:before {
  content: "\f108";
}
