//
// Typography
// --------------------------------------------------

body {
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
}

// Override hover and focus to have the same color of the links.
a:hover,
a:focus {
   color: $link-hover-color;
}

// Nice transition on hover.
a {
  transition: all 0.2s ease;
}

pre {
  // Reset Bootstrap rounded corners on <pre>.
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;

  // Make the color and borders lighter
  background-color: #fefefe;
  border-color: $gray-lighter;
}

// h5 is often used as an alternative for a **small title**
h5 {
  font-weight: bold;
  font-size: 15px;
}

// Make a paragraph stand out by adding .emphasis.
.emphasize {
  @include larger-text;
}

// The default Bootstrap style for quoting is horrible for long text portions.
.longquote p {
  font-size: 16px;
  line-height: 1.6;
}


//
// Grid
// --------------------------------------------------

// Faded out hr
hr.soften {
  margin: 30px 0;
  height: 1px;
  border: 0;
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
  background-image:    -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
  background-image:     -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
  background-image:      -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,.1), rgba(0,0,0,0));
}

hr.separator {
  width: 940px;
  margin: 0 auto;
}


//
// Layout
// --------------------------------------------------

.container {
  width: 970px !important;
}

#header .container,
#footer .container,
#content {
  padding-top: 20px;
  padding-bottom: 20px;
}


hr {
  border-top: 1px solid $gray-lighter;
}

#logo {
  h1 {
    margin: 0;
  }

  a {
    font-size: 56px;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: -.05em;
    line-height: 120%;
    text-shadow: #ccc 1px 1px 0px;
  }

  a,
  a:hover {
    color: #111;
    text-decoration: none;
  }

  .target {
    color: $gray-light;
    font-family: Georgia, "Bitstream Vera Serif", Times, serif;
    font-size: 35px;
    margin-left: -5px;
    text-shadow: #ccc 1px 1px 0px;
  }
}

#nav {
  ul {
    margin-top: 30px;
    list-style-type: none;

    text-align: right;
    font-size: 13px;
    text-transform: uppercase;

    li {
      display: inline;
      padding: 0 0 0 1.25em;

      &.current a,
      &.current a:hover {
        color: #999;
        border-bottom: 2px solid #999;
      }
    }

    a {
      padding-bottom: 2px;
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid #06a;
        text-decoration: none;
      }
    }
  }
}

#footer {
  text-align: center;
  font-size: 14px;
  color: $gray-light;

  p {
    font-size: 13px;
    margin: 0;
  }

  a {
    color: $gray;
  }
  a:hover {
    color: $gray-dark;
  }
}

#content {
  // Override Bootstrap margin-top
  .page-header {
    margin-top: 20px;
  }
}
