//
// Variables
// --------------------------------------------------

// Customizations
// -------------------------

// @brand-primary: #0066aa;
// @font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// @font-family-monospace: Consolas, Menlo, Monaco, "Courier New", monospace";

// SCSS
// -------------------------

$gray-darker:   lighten(#000, 13.5%) !default; // #222
$gray-dark:     lighten(#000, 20%) !default;   // #333
$gray:          lighten(#000, 33.5%) !default; // #555
$gray-light:    lighten(#000, 60%) !default;   // #999
$gray-lighter:  lighten(#000, 93.5%) !default; // #eee

$text-color:            #333333 !default;

$link-color:            #0066aa !default;
$link-hover-color:      darken($link-color, 10%) !default;
$link-gray-color:       lighten(#000, 50%) !default;

$line-height-large:     1.33 !default;
$line-height-small:     1.5 !default;
$line-height-bigger:    1.7 !default;


@mixin align-left {
  float: left;
  display: inline;
}

@mixin align-right {
  float: right;
  display: inline;
}

@mixin align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin image-align-left {
  @include align-left;
  padding: 4px;
  margin: 0 7px 2px 0;
}

@mixin image-align-right {
  @include align-right;
  padding: 4px;
  margin: 0 0 2px 7px;
}

@mixin image-align-center {
  @include align-center;
  width: auto;
}

@mixin larger-text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: $line-height-bigger;

  & + & {
    margin-top: -10px;
  }

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

@mixin links-gray {
  a {
    color: $link-gray-color;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    text-decoration: none;
  }
  a:hover {
    color: $link-color;
    text-decoration: none;
  }
}
