//
// Base styles
// --------------------------------------------------

// Increase font size. It looks more readable.
body {
  font-size: 16px;
  line-height: 1.6;
}

// The default Bootstrap style for quoting is horrible for long text portions.
blockquote p {
  font-size: 16px;
  line-height: 1.6;
}

// Alignment helpers.
.align-left {
  @include align-left;
}
.align-center {
  @include align-center;
}
.align-right {
  @include align-right;
}

// Image alignment helpers.
img.align-left {
  margin: 0 7px 2px 0;
  padding: 5px;
}
img.align-center {
  width: auto;
}
img.align-right {
  margin: 0 0 2px 7px;
  padding: 5px;
}

//#content .container {
//  max-width: 800px;
//}
